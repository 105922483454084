import Vue from 'vue'
import VueRouter from 'vue-router'
import loadIng from '../views/loading.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: loadIng
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('../views/loading.vue')
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/HomeViewPro',
    name: 'HomeViewPro',
    component: () => import('../views/HomeViewPro.vue')
  },
  {
    path: '/commonTwo',
    name: 'commonTwo',
    component: () => import('../views/commonTwo.vue')
  },
  {
    path: '/commonTwoPro',
    name: 'commonTwoPro',
    component: () => import('../views/commonTwoPro.vue')
  },
  {
    path: '/baseView',
    name: 'baseView',
    component: () => import('../views/baseView.vue')
  },
  {
    path: '/baseViewPro',
    name: 'baseViewPro',
    component: () => import('../views/baseViewPro.vue')
  },
  // 厦门小歪专用过审模版
  {
    path: '/baseViewXmxy',
    name: 'baseViewXmxy',
    component: () => import('../views/baseViewXmxy.vue')
  },
  // 启迪专用
  {
    path: '/commonPage',
    name: 'commonPage',
    component: () => import('../views/commonPage.vue')
  },
  {
    path: '/commonPagePro',
    name: 'commonPagePro',
    component: () => import('../views/commonPagePro.vue')
  },
  {
    path: '/QiDiTemplate',
    name: 'QiDiTemplate',
    component: () => import('../views/QiDiTemplate.vue')
  },
  // 基础通用模版 后台管理系统上传图片前端接受图片 进行替换产品
  {
    path: '/universalTemplate',
    name: 'universalTemplate',
    component: () => import('../views/universalTemplate.vue')
  },
  // 基础通用模版 (第二套 换了登录按钮和活动说明的样式)
  {
    path: '/universalTwoTemplate',
    name: 'universalTwoTemplate',
    component: () => import('../views/universalTwoTemplate.vue')
  }, 
  // 中转页模版
  {
    path: '/transferPage',
    name: 'transferPage',
    component: () => import('../views/transferPage.vue')
  }, 
  // 活动通用模版 
  {
    path: '/activeCommonTemplate',
    name: 'activeCommonTemplate',
    component: () => import('../views/activeCommonTemplate.vue')
  }, 
  // 活动通用2模版 (提交按钮文字修改 运营说 文字要还 不然转化不好 后续做个功能之后这个就可以废弃了)
  {
    path: '/activeBTNTemplate',
    name: 'activeBTNTemplate',
    component: () => import('../views/activeBTNTemplate.vue')
  }, 
  // 互客模版一
  {
    path: '/HuKeTemplate',
    name: 'HuKeTemplate',
    component: () => import('../views/HuKeTemplate.vue')
  }, 
  // 互客模版二
  {
    path: '/HuKeTwoTemplate',
    name: 'HuKeTwoTemplate',
    component: () => import('../views/HuKeTwoTemplate.vue')
  }, 
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
