<template>
  <div class="about">
    <van-loading color="#0094ff" vertical>加载中...</van-loading>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    let location = window.location.href;
    // let location = "https://testm.sz-adtop.com/#/?p_code=TPAaaaaaf";      //启迪产品链接
    // let location = "https://testm.sz-adtop.com/#/?p_code=TPAtuatfe";         //测试中转页
    sessionStorage.setItem("location_url", location);
    var data = location.split("?")[1].split("&");
    var objs = {};
    data.map((item) => (objs[item.split("=")[0]] = item.split("=")[1]));
    this.p_code = objs.p_code.split("#")[0];
    this.getProduct(this.p_code);
  },
  methods: {
    // 获取产品
    getProduct(data) {
      let param = {
        p_code: data,
      };
      this.$http
        .post("mapi/promotion/getGoods", param)
        .then((res) => {
          if (res.data.goods.template_web_url == 'transferPage') {
            this.$router.push({ path: "/"+res.data.goods.template_web_url,query: {p_code:this.p_code,c_code:res.data.goods.code}});
          }else{
            this.$router.push({ path: "/"+res.data.goods.template_web_url,query: {p_code:this.p_code}});
          }
        })
        .catch((err) => {
        });
    },
  },
};
</script>
<style lang="scss">
.about {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>