import CryptoJS from 'crypto-js';
const key = CryptoJS.enc.Utf8.parse("9e5702ead4d643fd"); // 作为密钥
// aes加密
 export const encrypt = (word) => {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }